'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import {BaseField, BaseFieldType} from "../../../../data/basefield.data";
import {RolePrivilege} from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import {PluginTemplate} from "../edit.unit.modal/edit.unit.modal.data";
import {EProvider} from "../../../../data/admin.data";
import angular = require("angular");

require('./edit.plugin.modal.css');

/* @ngInject */
export default class EditPluginModalController {
  public $scope: IScope;
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $uibModalInstance: any;
  public $uibModal: any;
  public dataService: any;
  public plugin: any;
  public pipeline: any;
  public unit: any;
  public isAdmin: boolean;
  public account: any;
  public isTemplate: boolean;
  public isDerivedFromTemplateOrViewMode: boolean;
  public isAddingTemplateLoading: boolean = false;
  public isWorking: boolean = false;
  public tab: string = CurrentTab.SETTINGS;
  public hasSettings: boolean = false;
  public hasOptionalSettings: boolean = false;
  public fields: any[] = [];
  public personSelectField: any;
  public temp: any;
  public formValid: any;
  public units: any;
  public preSavePluginTemplate: any;
  public isLoading: boolean = false;
  public hasEditRight: boolean = false;
  public additionalTabs: AdditionalTab[] = [];
  public googleLink:string;
  public azureLink:string;
  public tokenType:string;
  public providers: EProvider[] = [];

  constructor($scope: IScope, $log: ILogService, $rootScope: IRootScopeService, public privilegeService: PrivilegeService, public restService: RestService, $uibModalInstance, $uibModal, dataService, plugin, pipeline, unit, isAdmin: boolean, private Notification, private $translate) {
    this.$scope = $scope;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.plugin = plugin;
    this.pipeline = pipeline;
    this.unit = unit;
    this.isAdmin = isAdmin;
    this.account = this.dataService.getAccount();
    this.isTemplate = angular.isDefined(this.plugin.id);
    this.hasEditRight = this.privilegeService.has(RolePrivilege.Units_Edit) || this.privilegeService.has(RolePrivilege.Units_Edit_Custom);
    this.isDerivedFromTemplateOrViewMode = angular.isDefined(this.plugin.templateId) && this.plugin.templateId > 0 || !this.hasEditRight;
    this.providers=[EProvider.User,EProvider.Azure];


    this.temp = {
      pluginTemplateDescription: ''
    };

    // Gather plugins
    this.plugin.pluginData.forEach((field: BaseField) => {
      if (field.optional) {
        this.hasOptionalSettings = true;
      }
      switch (field.type) {
        case BaseFieldType.PERSON_SELECT:
        case BaseFieldType.DATABASE_PERSON_SELECT:
          this.personSelectField = field;
          break;
      }
      // Gather categories
      if (field.category) {
        const tab = this.additionalTabs.filter(entry => entry.category === field.category);
        if (tab.length === 0) {
          const newTab = {
            category: field.category,
            fields: []
          } as AdditionalTab;
          this.additionalTabs.push(newTab);
          tab.push(newTab);
        }
        tab[0].fields.push(field);
      }
    });

    this.selectTab(CurrentTab.SETTINGS);

    this.restService.loadUnits((units) => {
      this.units = units;
    }, () => {
      // Ignore
    });

    this.updateTokenType();
    let updateTokenType = setInterval(() => this.updateTokenType(), 15 * 1000);
    this.$scope.$on('$destroy', () => {
      clearInterval(updateTokenType);
    });
  }

  selectTab(selectedTab: string) {
    this.tab = selectedTab;

    switch (selectedTab) {
      case CurrentTab.GROUPS:
      case CurrentTab.PERSONS:
        break;
      case CurrentTab.SETTINGS:
      case CurrentTab.OPTIONAL:
      default:
        this.fields = [];
        this.plugin.pluginData.forEach((field: BaseField) => {
          switch (field.type) {
            case BaseFieldType.PERSON_SELECT:
            case BaseFieldType.DATABASE_PERSON_SELECT:
              break;
            default:
              switch (selectedTab) {
                case CurrentTab.SETTINGS:
                  if (!field.optional && !field.category) {
                    this.fields.push(field);
                  }
                  break;
                case CurrentTab.OPTIONAL:
                  if (field.optional && !field.category) {
                    this.fields.push(field);
                  }
                  break;
                default:
                  // Additional category
                  if (field.category === this.tab) {
                    this.fields.push(field);
                  }
                  break;
              }
          }
        });

        this.hasSettings = this.fields.length > 0;
        break;
    }



  }


  /**
     Delete an existing plugin template by id
   */
  deletePluginTemplate() {
    this.dataService.deletePluginTemplateById(this.plugin, (templates) => {
      this.$rootScope.$broadcast('updated.plugin.templates', {
        templates: templates,
        template: this.plugin
      });
      this.$uibModalInstance.close();
    }, (response) => {
      //Error occured
      this.$log.error(response);
    });
  }

  notOptional(field: BaseField) {
    return !field.optional;
  }

  optional(field: BaseField) {
    return field.optional;
  }

  getIcon(abstractName: string) {
    return 'icon-' + abstractName.replace(/.*\./, '');
  }

  /**
    Add as a new plugin template
  */
  addPluginTemplate() {
    if (this.preSavePluginTemplate) {
      //Save
      this.isAddingTemplateLoading = true;
      this.dataService.addPluginTemplate(this.temp.pluginTemplateDescription, this.plugin, (template: PluginTemplate) => {
        this.isAddingTemplateLoading = false;
        this.preSavePluginTemplate = false;
        this.plugin.templateId = template.template.id;
      }, (errorResponse) => {
        //Error occured
        this.isAddingTemplateLoading = false;
        this.$log.error(errorResponse);
      });
    } else {
      //Show input field for description
      this.preSavePluginTemplate = true;
    }
  }

  /**
   * Close the modal
   */
  cancel() {
    this.$uibModalInstance.close();
  }

  checkPipelineForUpdatedPluginTemplates(pipe, updatedPluginTemplate) {
    for (var i = 0; i < pipe.length; i++) {
      var plugin = pipe[i];
      if (plugin.nodes !== undefined && plugin.nodes.length !== 0) {
        this.checkPipelineForUpdatedPluginTemplates(plugin.nodes, updatedPluginTemplate);
      }
      if (plugin.templateId === updatedPluginTemplate.id) {
        plugin.pluginData = updatedPluginTemplate.pluginData;
        plugin.note = updatedPluginTemplate.note;
        plugin.quickEditActive = updatedPluginTemplate.quickEditActive;
      }
    }
  }

  /**
   * Save the pipeline
   */
  save() {
    if (this.isTemplate) {
      this.isWorking = true;

      //Save template
      this.dataService.updatePluginTemplate(this.plugin, (response) => {
        this.checkPipelineForUpdatedPluginTemplates(this.pipeline, this.plugin);
        this.isLoading = false;
        this.$uibModalInstance.close();
      }, (errorResponse) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(errorResponse);
      });
    } else {
      this.isWorking = true;

      this.restService.savePipeline(this.unit.id, this.pipeline, true, null, (response) => {
        this.$log.debug('Pipeline validated!');
        this.$rootScope.$emit('pipeline.validated', response);
        this.$uibModalInstance.close();
      }, (err) => {
        this.$log.error(err);
      });

    }

  }

  help() {
    if (this.plugin.help && this.plugin.help.startsWith('http')) {
      // Open in browser
      window.open(this.plugin.help, '_blank').focus();
    } else {
      //Show help modal
      this.$uibModal.open({
        template: require('../../../modals/pipeline/help.modal/help.modal.html'),
        controller: 'ModalShowHelpController',
        controllerAs: 'ctrl',
        size: 'lg',
        resolve: {
          urlToWiki: () => {
            return this.plugin.help;
          }
        }
      });
    }
  }

  isOauthProvider(): Boolean {
    const prov = this.getProvider();
    return prov === EProvider.Azure || prov === EProvider.Google
  }

  getProvider(){
    const provider = this.plugin.pluginData.filter((item) => item.key === 'provider');
    if (!provider || provider.length !== 1) {
      return undefined;
    }
    return provider[0].value;
  }

  goGoogle(){
    if(this.getProvider()!==EProvider.Google) {
      return;
    }
    this.restService.getGoogleLink(this.getUsername()).then((response)=>{
      this.googleLink = response;
      window.open(this.googleLink, "_blank");
    })

  }
  getUsername():string {
    let username=this.plugin.pluginData.filter(function(item) {
      return item.key === 'from';
    });
    if(username.length==0){
      return null;
    }
    return username[0].value;
  }

  goAzure(){
    if(this.getProvider()!==EProvider.Azure) {
      return;
    }
    this.restService.getAzureLink(this.getUsername()).then((response)=>{
      this.azureLink = response;
      window.open(this.azureLink, "_blank");
    })

  }

  updateTokenType(){
    if(this.getProvider()!==EProvider.User && this.tokenType!==this.getProvider()){
      let email = this.getUsername()
      if(email!=null)
        this.restService.updateTokenType(email).then((response)=>{
          this.tokenType = response;
          this.$scope.$apply();
        })
    }
  }

  updateHost(prov:EProvider){
    let host=this.plugin.pluginData.filter(function(item) {
      return item.key === 'host';
    });
    let port=this.plugin.pluginData.filter(function(item) {
      return item.key === 'port';
    });
    let ssl=this.plugin.pluginData.filter(function(item) {
      return item.key === 'secure';
    });

    if(prov===EProvider.Google) {
      host[0].value='imap.gmail.com'
      port[0].value=587
      ssl[0].value=true
    }
    if(prov===EProvider.Azure) {
      host[0].value='smtp.office365.com'
      port[0].value=587
      ssl[0].value=true
    }
  }

  changeProvider(field:BaseField,prov:EProvider){
    field.value=prov;
    this.updateHost(prov);
    this.updateTokenType();
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }
}

enum CurrentTab {
  SETTINGS = 'SETTINGS',
  OPTIONAL = 'OPTIONAL',
  PERSONS = 'PERSONS',
  GROUPS = 'GROUPS'
}

interface AdditionalTab {
  category: string,
  fields: BaseField[]
}
