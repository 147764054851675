'use strict';

import {ILogService, IRootScopeService, IScope, ITimeoutService} from "angular";
import {EFE2Features, UserAccount} from "../../../data/account.data";
import {AlarmData, AlarmDataPage, AlarmDataSimple} from "../../../data/alarm.data";
import {AvailabilitySummaryResponse} from "../../../data/availability.data";
import {RolePrivilege} from "../../../data/privileges.enum";
import {EServerState, WebSocketServerStateEvent} from "../../../data/socket.data";
import PrivilegeService from "../../../services/privilege.service";
import RestService from "../../../services/rest.service";

require('./home.view.component.css');

/* @ngInject */
export default class HomeController {
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $state: any;
  public $log: ILogService;
  public $uibModal: any;
  public restService: RestService;
  public dataService: any;
  public $q: any;
  public $timeout: ITimeoutService;
  public Notification: any;
  public accountService: any;
  public listeners = [];

  public account: UserAccount;

  public incomingAlert = false;
  public hasAvailability = false;
  public deletionOfAlarmsDeactivated = false;
  public isLoadingAlarms = false;
  public isShiftBookActive = false;

  public availabilityResult: AvailabilitySummaryResponse;
  public isDateSearch = false;
  public jumpToDate: Date;

  public currentPage = 0;
  public limit = 50;
  public data = {
    hasPrevious: false,
    hasNext: false,
    data: []
  } as AlarmDataPage;

  public filter = {
    searchFilter: '',
    reverseOrdering: true
  } as PageFilter;


  constructor($rootScope: IRootScopeService,
    $scope: IScope, $state, $log: ILogService, $q, $timeout: ITimeoutService, $uibModal, dataService, restService: RestService, Notification, accountService,
    public privilegeService: PrivilegeService) {
    $log.debug('HomeController started...');
    this.jumpToDate = new Date();
    this.jumpToDate.setMilliseconds(0);
    this.jumpToDate.setSeconds(0);
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.restService = restService;
    this.dataService = dataService;
    this.$q = $q;
    this.$timeout = $timeout;
    this.Notification = Notification;
    this.accountService = accountService;

    this.initListeners();

    if (this.dataService.hasAccount()) {
      this.init();
    }
  }

  /**
   * Init event listeners
   */
  initListeners() {
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', (event, account) => {
      this.$log.debug('HomeController: new.account triggered!');
      //Init controller
      this.init();
    }));
    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('delete.account', () => {
      this.$log.debug('HomeController: delete.account triggered!');
      this.account = undefined;
    }));

    // Wait for incoming alarm signal
    this.listeners.push(this.$rootScope.$on('server.state', (event, data: WebSocketServerStateEvent) => {
      this.$log.debug('HomeController: server.state triggered: ' + data.state);
      this.incomingAlert = data.state === EServerState.WORKING;
      this.$scope.$apply();
    }));

    //Wait for new alarms
    this.listeners.push(this.$rootScope.$on('new.alarm', (event, alarm: AlarmDataSimple) => {
      this.$log.debug('HomeController: new.alarm triggered!');
      alarm.isNew = true;

      if (this.isOnFirstPageAndNoSearchFilter()) {
        // Only add alarm if user is on first page and no search filter oder date search is active
        this.data.data.splice(0, 0, alarm);
        // Delete last alarm (to avoid overflow)
        if (this.data.data.length > this.limit) {
          this.data.data.pop();
        }
      }

      this.$scope.$applyAsync();
    }));

    // Wait for deletion of an alarm
    this.listeners.push(this.$rootScope.$on('single.alarm.deleted', (event, alarm: AlarmDataSimple) => {
      const index = this.data.data.indexOf(alarm);
      if (index >= 0) {
        this.data.data.splice(index, 1);
        if (this.data.data.length <= 0) {
          // No entries left, reload page
          this.currentPage = 0;
          this.pageChanged();
        }
      }

    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  isOnFirstPageAndNoSearchFilter(): boolean {
    return this.currentPage === 0 && this.filter.searchFilter === '' && !this.isDateSearch
  }

  init() {

    if (this.restService.isFirstAdminLogin()) {
      this.$log.debug('HomeController: Erster Admin-Login. Der Administrator muss das Passwort ändern');
      this.$uibModal.open({
        template: require('../../modals/admin/change.pass.modal/change.pass.modal.html'),
        controller: 'ChangePassModalController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg'
      });
    }

    if (!this.privilegeService.has(RolePrivilege.Home)) {
      this.$state.go('main.' + this.dataService.selectPageForPrivilege());
      return;
    }

    this.$log.info('HomeController: Init home view...');
    //Set Scope
    this.account = this.dataService.getAccount();

    this.hasAvailability = this.account.hasOnlineService;
    this.deletionOfAlarmsDeactivated = this.account.deletionOfAlarmsDeactivated;
    this.isShiftBookActive = this.accountService.isFeatureEnabled(EFE2Features.SHIFT_BOOK);

    //Load all required data
    //1. Alarms
    if (this.privilegeService.has(RolePrivilege.Home_Alarms_List)) {
      this.pageChanged();
    }

    // 2. Availability
    if (this.privilegeService.has(RolePrivilege.Availability) && this.hasAvailability) {
      this.loadAvailabilitySummary();
    }
  }

  loadAvailabilitySummary() {
    this.restService.getAvailabilitySummary().then(result => {
      this.availabilityResult = result;
    }).finally(() => {
      this.$scope.$applyAsync();
    })
  }


  /**
   * Checks if user has privilege or not
   * TODO
   */
  hasPrivilege(privilege: RolePrivilege) {
    return this.privilegeService.has(privilege);
  }

  //#############
  //ALARM FUNCTIONS
  //#############

  showAll() {
    this.limit = 0;
  }

  nextPage() {
    this.currentPage++;
    this.pageChanged();
  }

  previousPage() {
    if (this.currentPage === 0) return;
    this.currentPage--;
    this.pageChanged();
  }

  refresh() {
    this.currentPage = 0;
    this.pageChanged();
  }


  pageChanged() {
    // Verify if user has access
    if (!this.privilegeService.has(RolePrivilege.Home_Alarms_List)) {
      return;
    }

    //Load alarm for user filtered by search string
    this.isLoadingAlarms = true;

    let ordering = this.filter.reverseOrdering ? 'DESC' : 'ASC';

    this.restService.loadAlarmsPaginated(this.currentPage, this.limit, this.filter.searchFilter, ordering, this.isDateSearch ? this.jumpToDate.getTime() : 0).then((response: AlarmDataPage) => {
      this.data = response;
      // Clear the icoming alert icon
      this.$rootScope.$emit('clear.alarm');
    }).catch((response) => {
      this.$log.error(response);
    }).finally(() => {
      this.isLoadingAlarms = false;
    });
  }

  updateColumnOrdering() {
    this.filter.reverseOrdering = !this.filter.reverseOrdering;
    this.pageChanged();
  }

  /**
    Select an alarm and show it
  */
  openAlarm(alarm: AlarmData) {
    if (!this.privilegeService.has(RolePrivilege.Home_Alarms_Details)) {
      return;
    }

    this.$uibModal.open({
      template: require('../../modals/alarms/alarm.modal/alarm.modal.html'),
      controller: 'AlarmModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        alarm: () => {
          return alarm;
        }
      }
    });
  };

  /**
    Search for specific alarm
  */
  onSearchFilterChanged() {
    this.currentPage = 0; // Reset current page to first page
    this.pageChanged();
  };

  /**
    Reset the search filter
  */
  resetSearchFilter() {
    this.filter.searchFilter = '';
    this.currentPage = 0; // Reset current page to first page
    this.pageChanged();
  };

  searchForDate() {
    if (!this.jumpToDate) return;
    this.currentPage = 0;
    this.isDateSearch = true;
    this.pageChanged();
  }


  resetDateSearch() {
    this.isDateSearch = false;
    this.jumpToDate = new Date();
    this.jumpToDate.setMilliseconds(0);
    this.jumpToDate.setSeconds(0);
    this.currentPage = 0;
    this.pageChanged();
  }
}


interface PageFilter {
  searchFilter: string,
  orderByField: string,
  reverseOrdering: boolean
}