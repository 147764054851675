import {RestProtocol} from "../../../../../data/admin.data";
import {IScope} from "angular";
import PrivilegeService from "../../../../../services/privilege.service";

require("./rest.protocol.info.component.scss")
/* @ngInject */
export default class RestProtocolInfoComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./rest.protocol.info.component.html');
    this.scope = {
      protocol: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = RestProtocolInfoController;

    console.log("RestProtocolInfoComponent")
  }
}

class RestProtocolInfoController {
  public protocol: RestProtocol;

  constructor(private $scope: IScope,
              public privilegeService: PrivilegeService,
              private $uibModal: any,
              private dataService: any,
              private accountService: any) {
  }

  parseDate(): Date {
    if (!this.protocol) return new Date();
    return new Date(this.protocol.timestamp);
  }
}
