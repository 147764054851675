import angular from 'angular';
import 'angular-ui-bootstrap'

import AlarmTextBaseFieldComponent from './alarmtext.base.field/alarmtext.base.field.ts';
import TextBaseFieldComponent from './text.base.field/text.base.field';
import PasswordBaseFieldComponent from './password.base.field/password.base.field';
import CheckboxBaseFieldComponent from './checkbox.base.field/checkbox.base.field';
import NumberBaseFieldComponent from './number.base.field/number.base.field';
import DropdownBaseFieldComponent from './dropdown.base.field/dropdown.base.field';
import TableBaseFieldComponent from './table.base.field/table.base.field.ts';
import PersonBaseFieldComponent from './person.base.field/person.base.field';
import AM4BaseFieldComponent from './am4.base.field/am4.base.field';
import AmobileBaseFieldComponent from './amobile.base.field/amobile.base.field';
import VehiclesBaseFieldComponent from './vehicles.base.field/vehicles.base.field';
import UnitsBaseFieldComponent from './units.base.field/units.base.field';
import ContentEditableTextBaseFieldComponent from './alarmtext.editor/alarmtext.editor.ts';
import MultiTextBaseFieldComponent from './multitext.base.field/multitext.base.field.ts';
import RelaisBaseFieldComponent from './relais.base.field/relais.base.field.ts';
import OnlineServiceBaseFieldComponent from "./onlineService.base.field/onlineService.base.field";
import UnitCodeMapBaseFieldComponent from "./code.map.base.field/code.map.base.field";
import IOPrintBaseFieldComponent from "./ioprint.base.field/ioprint.base.field";
import OsFunctionGroupDropdownFieldComponent from "./osfunctiongroup.dropdown.field/osfunctiongroup.dropdown.field";

export default angular.module('FE2.components.pipeline', ['ui.bootstrap'])
  .directive('alarmtextBaseField', () => new AlarmTextBaseFieldComponent())
  .directive('multitextBaseField', () => new MultiTextBaseFieldComponent())
  .directive('textBaseField', () => new TextBaseFieldComponent())
  .directive('passwordBaseField', () => new PasswordBaseFieldComponent())
  .directive('checkboxBaseField', () => new CheckboxBaseFieldComponent())
  .directive('numberBaseField', () => new NumberBaseFieldComponent())
  .directive('dropdownBaseField', () => new DropdownBaseFieldComponent())
  .directive('tableBaseField', () => new TableBaseFieldComponent())
  .directive('personBaseField', () => new PersonBaseFieldComponent())
  .directive('am4BaseField', () => new AM4BaseFieldComponent())
  .directive('ioprintBaseField', () => new IOPrintBaseFieldComponent())
  .directive('amobileBaseField', () => new AmobileBaseFieldComponent())
  .directive('vehiclesBaseField', () => new VehiclesBaseFieldComponent())
  .directive('unitsBaseField', () => new UnitsBaseFieldComponent())
  .directive('relaisBaseField', () => new RelaisBaseFieldComponent())
  .directive('alarmtext', () => new ContentEditableTextBaseFieldComponent())
  .directive('onlineServiceField', ()=>new OnlineServiceBaseFieldComponent())
  .directive('codeMapBaseField', ()=> new UnitCodeMapBaseFieldComponent())
  .directive('osFunctionGroupDropdown', ()=> new OsFunctionGroupDropdownFieldComponent())
  .name;
