'use strict';

import { IRootScopeService, IScope } from "angular";
import { EFinishMode, EQueueEntryState, QueueEntryResponse, UpdateQueuePriorityRequest, UpdateQueueStateRequest } from "../../../../data/queue.data";

import RestService from "../../../../services/rest.service";
import * as L from "leaflet";
import MapService from "../../../../services/map.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

'use strict';

import angular = require("angular");
import {UserAccount} from "../../../../data/account.data";
import {VehicleNameIDResponse} from "../../../../data/vehicles.data";

require('./queue.entry.scss');

export default class QueueEntryComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      entry: '=',
      qact: '='
    }
    this.template = require('./queue.entry.html');

    this.controller = QueueEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class QueueEntryComponentController {
  private entry: QueueEntryResponse;
  private listeners = [];
  private expanded: boolean = false;
  private highlighted: boolean = false;
  private hasQueueEdit: boolean = false;
  public $uibModal: any;
  public marker: L.Marker;
  public map;
  public searchString: string;
  private account: UserAccount;
  public foundVehicles: VehicleNameIDResponse[];
  public selectedVehicles: VehicleNameIDResponse[];
  public loadingVehicle: boolean;
  public searchedVehicle: VehicleNameIDResponse;
  public locationString: string;

  constructor(public $scope: IScope, public $rootScope: IRootScopeService, public mapService: MapService, public restService: RestService, private privilegeService: PrivilegeService,
    dataService, $uibModal) {
    this.$uibModal = $uibModal;
    this.hasQueueEdit = privilegeService.has(RolePrivilege.Alarm_Queues_Edit);
    this.initListeners();


    this.account = dataService.getAccount();
  }


  initListeners() {
    this.listeners.push(this.$scope.$watch('ctrl.entry', (oldValue, newValue) => {
      if (newValue) {
        this.listeners.push(this.$rootScope.$on(`queue.${this.entry.queueId}.update.state`, (event, queueResponse) => {
          this.entry.queueActive = queueResponse.active;
        }));
        if (this.entry.assignedVehicles) {
          this.selectedVehicles = this.entry.assignedVehicles;
          if (this.entry.assignedVehicles.length >= 1) {
            this.selectedVehicles = this.entry.assignedVehicles.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          }
        }
      }
    }));
    // Highlight element if there is an hover event
    this.listeners.push(this.$rootScope.$on('queue.entry.mouse.enter', (event, queueResponse: QueueEntryResponse) => {
      if (this.entry.id === queueResponse.id) return;
      this.highlighted = this.entry.externalId === queueResponse.externalId;
    }));
    this.listeners.push(this.$rootScope.$on('queue.entry.mouse.leave', (event, queueResponse) => {
      if (this.highlighted) {
        this.highlighted = false;
      }
    }));

    this.$scope.$on('$destroy', () => {
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  vehicleSelected(item: VehicleNameIDResponse) {
    if (!this.selectedVehicles) {
      this.selectedVehicles = [];
    }
    if (!this.selectedVehicles.some(entry => entry.id === item.id)) {
      this.selectedVehicles.push(item);
      if (this.selectedVehicles.length >= 2) {
        this.selectedVehicles.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      }
      this.assignVehicle(item);
    }
  }


  assignVehicle(item: VehicleNameIDResponse) {
    this.restService.assignVehicleToQueue(this.entry.id, item.id).then(response => {
      this.entry.assignedVehicles = response.assignedVehicles ?? [];
      this.selectedVehicles = this.entry.assignedVehicles.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  changeVehicle() {
  }

  removeVehicle(item: VehicleNameIDResponse) {
    this.restService.removeVehicleFromQueue(this.entry.id, item.id).then(response => {
      this.entry.assignedVehicles = response.assignedVehicles;
      this.selectedVehicles = response.assignedVehicles;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  searchVehicle(val) {
    return new Promise<any>(resolve => {
      this.restService.searchForVehiclesAccessibleForUser(val, this.account.id, 10).then((searchResult: any[]) => {
        //Filter out already selected vehicles
        let filtered = [];
        if (searchResult) {
          filtered = searchResult.filter(element => {
            if (this.selectedVehicles) {
              return this.selectedVehicles.find(entry => entry.id === element.id) === undefined;
            }
            return true;
          });
        }
        resolve(filtered);
      });
    });
  }

  priorityUp($event) {
    $event.stopPropagation();

    if (this.entry.state === EQueueEntryState.DONE) return;

    if (this.entry.priority === 4) {
      this.entry.priority = 1;
    } else {
      this.entry.priority++;
    }
    this.changePriority(this.entry.priority);
  }

  changePriority(newPriority: number): void {
    this.restService.updateQueueEntryPriority(this.entry.id, {
      priority: newPriority
    } as UpdateQueuePriorityRequest).then(result => {
      this.entry.priority = result.priority;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  mouseEnter(): void {
    this.$rootScope.$emit('queue.entry.mouse.enter', this.entry);
  };

  mouseLeave(): void {
    this.$rootScope.$emit('queue.entry.mouse.leave', this.entry);
  }

  assign(): void {
    if (!this.entry.queueActive || !this.hasQueueEdit) return;
    this.restService.updateQueueEntryState(this.entry.id, {
      state: EQueueEntryState.IN_PROGRESS,
      queueId: this.entry.queueId
    } as UpdateQueueStateRequest);
  }

  done(mode: EFinishMode): void {

    this.$uibModal.open({
      template: require('../../../modals/queues/confirm.queue.close.modal/confirm.queue.close.modal.html'),
      controller: 'ConfirmQueueCloseModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      backdrop: 'static',
      resolve: {
        okFunction: () => {
          return (reportMessage) => {
            this.restService.updateQueueEntryState(this.entry.id, {
              state: EQueueEntryState.DONE,
              finishMode: mode,
              queueId: this.entry.queueId,
              reportMessage
            } as UpdateQueueStateRequest);
          }
        }
      }
    });
  }

  toggleExpand() {
    this.expanded = !this.expanded;
    this.expand();
  }

  expand(): void {
    if (this.expanded) {
      // Show
      if (this.entry.hasMap) {
        // Wait a little bit after div is displayed
        setTimeout(() => {
          this.initMap();
        }, 250);
      }
    } else {
      // Hide
      let doc = document.getElementById(`${this.entry.id}_expansion`);
      if (doc) {
        doc.classList.add('swing-out-top-bck');
        setTimeout(() => {
          this.$scope.$applyAsync();
        }, 500);
      }

    }
  }

  initMap() {
    L.Icon.Default.imagePath = '/img/static';
    const latLng = L.latLng(this.entry.alarmData.parameters['lat'], this.entry.alarmData.parameters['lng']);

    this.map = L.map(`${this.entry.id}_map`, { attributionControl: false, zoomControl: false }).setView(latLng, 13);
    let layers = this.mapService.getBaseLayers();
    let selectedLayer = this.mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);
    this.marker = L.marker(latLng, { draggable: false }).addTo(this.map);
  }


  /**
   * Remove hover css class to avoid weird hover effects
   */
  onMouseEnter($event): void {
    angular.element($event.target).parent().removeClass('queue-entry-hover');
  }

  /**
   * Add hover css again to parent
   */
  onMouseLeave($event): void {
    if (this.entry.state !== EQueueEntryState.ASSIGNED) return;
    angular.element($event.target).parent().addClass('queue-entry-hover');
  }
}
