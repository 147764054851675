'use strict';

import angular = require("angular");
import {
  EInputFallbackCategory,
  InputPlugin,
  InputPluginStateRequestType,
  NewInputPluginStateRequest
} from "../../../../data/input.data";

import AdminService from "../../../../services/admin.service";
import RestService from "../../../../services/rest.service";

require('./admin.input.tablerow.component.css');

export default class InputTablerowComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      input: '='
    }
    this.template = require('./admin.input.tablerow.component.html');
    this.controller = InputTablerowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//parking
/* @ngInject */
class InputTablerowComponentController {
  public $scope: angular.IScope;
  public $log: angular.ILogService;
  public adminService: AdminService;
  public $rootScope: angular.IRootScopeService;
  public Notification: any;
  public $translate: any;
  public listeners: any;
  public isLoading: any;
  public input: InputPlugin;
  public $uibModal: any;
  public restService: RestService;
  public fallbackCategory: typeof EInputFallbackCategory = EInputFallbackCategory;


  public isConfigLoading = false;

  constructor($scope: angular.IScope, $rootScope: angular.IRootScopeService, $log: angular.ILogService, adminService: AdminService, $uibModal: any, $translate, Notification, restService: RestService) {
    this.$scope = $scope;
    this.$log = $log;
    this.$translate = $translate;
    this.adminService = adminService;
    this.Notification = Notification;
    this.$rootScope = $rootScope;
    this.listeners = [];
    this.$uibModal = $uibModal;
    this.isLoading = false;
    this.restService= restService;

    this.$scope.$watch('ctrl.input', (oldValue, newValue: InputPlugin) => {
      if (angular.isDefined(newValue) && this.listeners.length === 0) {
        this.initListeners();
      }
    });
  }

  initListeners() {
    //Wait for new account
    this.listeners.push(
      this.$rootScope.$on('input.change.' + this.input.id, (event, data: InputPlugin) => {
        this.$log.info(data);
        const currentTimestamp = this.input.state.timestamp;
        const newTimestamp = data.state.timestamp;
        if (newTimestamp > currentTimestamp) {
          this.input.state = data.state;
          this.input.active = data.active;
          this.$scope.$apply();
        } else {
          // Timestamp is older than the new one
        }
      })
    );

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Toggle the input state active/deactive
   */
  toggleActiveState() {

    const newInputStateActive = !this.input.active;
    let newState = InputPluginStateRequestType.START;

    if (newInputStateActive) {
      newState = InputPluginStateRequestType.START;
    } else {
      newState = InputPluginStateRequestType.STOP;
    }

    this.changeState(newState);
  }

  /**
   * Change the inputs state
   */
  changeState(newState: InputPluginStateRequestType) {
    this.transmitStateChangeToServer(newState);
  }

  /**
  * Change the inputs state
  */
  loadIOEspaConfigString() {
    this.isConfigLoading = true;
    this.adminService.loadIOEspaConfigString(this.input.id).then((result) => {
      navigator.clipboard.writeText(result).then(() => {
        this.$translate(['ADMIN.CLIPBOARD_MSG', 'ADMIN.CLIPBOARD_TITLE']).then((translations) => {
          this.Notification.success({
            message: translations['ADMIN.CLIPBOARD_MSG'],
            title: translations['ADMIN.CLIPBOARD_TITLE']
          });
        });
      });


    }).finally(() => {
      this.isConfigLoading = false;
      this.$scope.$applyAsync();
    });
  }

  delete(): void {

    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.adminService.deleteInputPluginById(this.input.id).then((result) => {
              this.$rootScope.$emit('update.input.plugins');
            }).finally(() => {
              this.isLoading = false;
              this.$scope.$applyAsync();
            });
          }

        }, additionalText: () => {
          return;
        }
      }
    });
  }

  /**
   * Transmit new state to server
   * @param {boolean, if active or not} active
   * @param {'START', 'STOP', or 'RESTART'} newState
   */
  transmitStateChangeToServer(newState: InputPluginStateRequestType) {

    const stateToTransmit = {
      state: newState
    } as NewInputPluginStateRequest;

    this.isLoading = true;
    this.adminService.updateInputState(this.input, stateToTransmit, ((response: InputPlugin) => {
      this.input.active = response.active;
      this.isLoading = false;
    }), ((err) => {
      this.$log.error('Could not update input state', err);
      this.isLoading = false;
    }));
  }

  editInput() {
    this.isLoading = true;
    this.restService.getInput(this.input.id).then((inputPluginDetails)=>{
      this.isLoading= false;
      this.$uibModal.open({
        template: require('../../../modals/admin/edit.input.modal/edit.input.modal.html'),
        controller: 'EditInputModalController',
        controllerAs: 'ctrl',
        size: 'lg',
        resolve: {
          inputPlugin: ()=>{
            return inputPluginDetails;
          },
          okFunction: ()=>{
            return (triggerReload)=>{
              if (triggerReload){
                this.$rootScope.$emit('update.input.plugins');
              }
            }
          }
        }
      });
    }).finally(()=>{
      this.isLoading= false;
    })

  }
}
