'use strict';

import {ILogService, IRootScopeService, IScope} from 'angular';
import {EFE2Features, UserAccount} from '../../../../data/account.data';
import {
  AMobile,
  AMobileType,
  EAdditionalLicence,
  ELicencePermissions,
  ELocationMapDiaryPermission,
  EMapDisplayMode,
  ESubmitStatusValues,
  LicenceAvailabilityResult,
  LocationMapsPermissions
} from '../../../../data/amobile.data';
import {Vehicle} from '../../../../data/vehicles.data';
import RestService from '../../../../services/rest.service';
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import HelperService from '../../../../services/helper.service';
import angular = require('angular');


require('./edit.amobile.modal.scss');

/* @ngInject */
export default class EditAmobileModalController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $uibModal: any;
  public $uibModalInstance: any;
  public dataService: any;
  public restService: RestService;
  public $log: angular.ILogService;
  public listeners = [];
  public model: AMobile;
  public licencePermissions: typeof ELicencePermissions= ELicencePermissions;
  public locationMapDiaryLicencePermission: typeof ELocationMapDiaryPermission= ELocationMapDiaryPermission;

  public hasForestFeature = false;
  public isLoading: boolean = true;
  public isEditMode: boolean;
  public hasVehicles: boolean;
  public showRemoveHereMapsLicenceWarning: boolean;
  public showRemoveMapTripLicenceWarning: boolean;
  public showRemoveDangerousGoodsLicenceWarning: boolean;
  public showRemoveKohlhammerLicenceWarning: boolean;
  public showRemoveVehicleCardsLicenceWarning: boolean;
  public showRemoveLocationMapsLicenceWarning: boolean;

  public dangerousGoodsLicenceStatus: LicenceAvailabilityResult;
  public hereMapsLicenceStatus: LicenceAvailabilityResult;
  public mapTripLicenceStatus: LicenceAvailabilityResult;
  public kohlhammerLicenceStatus: LicenceAvailabilityResult;
  public vehicleCardsLicenceStatus: LicenceAvailabilityResult;
  public locationMapsLicenceStatus: LicenceAvailabilityResult;
  public copyOfModel: AMobile;

  public vehicleSearch: string = '';
  public vehicles: Vehicle[] = [];
  public mapping: any;

  public mode = AmobileModes.GENERAL;
  public account: UserAccount;
  public okFunction;

  public priv:PrivilegeService;
  public isEditingOfFieldsAllowed:boolean=false;
  public trackingEnabledGlobally: boolean = true;
  public encryptionPasswordChanged: boolean = false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModal, $uibModalInstance, dataService, restService: RestService, $log: ILogService, aMobile: AMobile, okFunction, privilegeService: PrivilegeService,
    private helperService: HelperService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.account = dataService.getAccount();
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.$log = $log;
    this.okFunction = okFunction;
    this.priv= privilegeService;

    this.isEditMode = angular.isDefined(aMobile);
    if (this.isEditMode) {
      this.isEditingOfFieldsAllowed= this.priv.has(RolePrivilege.Addressbook_aMobile_Edit);
      this.model = aMobile;
      this.copyOfModel = angular.copy(this.model);
    } else {
      this.isEditingOfFieldsAllowed= this.priv.has(RolePrivilege.Addressbook_aMobile_Create);
      this.model = {
        type: AMobileType.AMOBILE,
        name: '',
        vehicle: undefined,
        vehicleId: undefined,
        submitStatus: ESubmitStatusValues.NO_STATUS,
        navigationStatus3: false,
        mapDisplayMode: EMapDisplayMode.ZOOM_ON_TARGET,
        password: this.helperService.generatePassword(20)
      } as AMobile;
    }

    this.initListeners();
    this.init();
  }

  init() {
    this.checkIfDangerousGoodsLicenceIsAvailable();
    this.checkIfHereMapsLicenceIsAvailable();
    this.checkIfMapTripLicenceIsAvailable();
    this.checkIfKohlhammerLicenceIsAvailable();
    this.checkIfVehicleCardsLicenceIsAvailable();
    this.checkIfLocationMapsLicenceIsAvailable();
    // Init object if it is undefined in aMobile object
    if (angular.isUndefined(this.model.locationMaps)){
      this.model.locationMaps = {permissions: ELicencePermissions.NO_ACCESS,
        confidentialDiaryPermission: ELicencePermissions.NO_ACCESS
      }as LocationMapsPermissions;
    }

    this.hasForestFeature = (this.dataService.getAccount() as UserAccount).features.includes(EFE2Features.BOSCH_FORREST);
    this.restService.getAmobileTrackingToggle()
      .then(active => {
        this.trackingEnabledGlobally = active;
        this.$scope.$applyAsync();
      })

  }


  initListeners() {

    this.$scope.$watch('ctrl.model', (aMobile: AMobile) => {
      // Init
      if (angular.isDefined(aMobile)) {
        this.listeners.push(
          this.$rootScope.$on('amobile.change.' + this.model.id, (event, data) => {
            this.model = data;
            this.$scope.$apply();
          })
        );

      }

    });

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Search for a vehicle
   */
  searchForVehicle() {
    if (this.vehicleSearch === '') {
      this.vehicles = [];
      return;
    }
    if (this.vehicleSearch.length < 2) {
      // Too few characters
      return;
    }

    this.restService.searchForVehicles(this.vehicleSearch).then(result => {
      this.vehicles = result as Vehicle[];
    }).catch((err) => {
      this.$log.error('Could not load vehicles', err);
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  cancel() {
    // Reset data
    if (angular.isDefined(this.copyOfModel)) {
      angular.forEach(this.copyOfModel, (value, key) => {
        this.model[key] = value;
      });
    }

    this.okFunction();
    this.$uibModalInstance.close();
  }

  /**
   * Remove the vehicle mapping
   */
  removeVehicle() {
    this.model.vehicle = undefined;
    this.model.vehicleId = undefined;
  };

  /**
   * Set the mapping between vehicle and aMobile
   */
  addVehicle(vehicle: Vehicle) {
    this.model.vehicle = vehicle;
    this.model.vehicleId = vehicle.id;
    // Reset search
    this.vehicleSearch = '';
    this.vehicles = [];
  };

  removeDevice() {
    this.model.connectedDeviceName = undefined;
    this.model.connectedDeviceId = undefined;
    this.generatePassword(false);

  }

  mapDisplayZoomOnTarget() {
    this.model.mapDisplayMode = EMapDisplayMode.ZOOM_ON_TARGET
  }

  mapDisplayZoomOnStartAndTarget() {
    this.model.mapDisplayMode = EMapDisplayMode.ZOOM_ON_START_AND_TARGET;
  }

  generatePassword(showHint: boolean) {
    this.model.password = this.helperService.generatePassword(20);
    this.save();
    this.encryptionPasswordChanged = showHint;
    this.$scope.$applyAsync();

  }


  /**
   * Add the new aMobile
   */
  add() {

    this.isLoading = true;
    const self = this;
    this.restService.addAMobile(this.model,
      () => {
        this.isLoading = false;
        this.$uibModalInstance.close();
        this.okFunction();
      },

      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });
  }

  /**
   * Save existing aMobile
   */
  save() {
    this.isLoading = true;
    this.restService.editAMobile(this.model, () => {
        this.isLoading = false;
        //Update aMobile mapping
        this.restService.loadAmobileMapping(() => {
          this.isLoading = false;
        }, (err) => {
          this.$log.error(err);
          this.isLoading = false;
        });
      },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });
  }

  /**
   * Switch the HERE maps toggle
   */
  switchHereMaps() {
    if (!this.isEditingOfFieldsAllowed){
      return;
    }
    if (!this.hereMapsLicenceStatus.available && !this.model.hereMaps) {
      // Not available and therefore switch to true not allowed
      return;
    }

    if (this.model.hereMaps) {
      // User wants to remove the licence > show warning
      this.showRemoveHereMapsLicenceWarning = true;
    } else {
      this.showRemoveHereMapsLicenceWarning = false;
    }

    this.model.hereMaps = !this.model.hereMaps;
  }

  /**
   * Switch the MapTrip toggle
   */
  switchMapTrip() {
    if (!this.isEditingOfFieldsAllowed) {
      return;
    }
    if (!this.mapTripLicenceStatus.available && !this.model.mapTrip) {
      // Not available and therefore switch to true not allowed
      return;
    }

    if (this.model.mapTrip) {
      // User wants to remove the licence > show warning
      this.showRemoveMapTripLicenceWarning = true;
    } else {
      this.showRemoveMapTripLicenceWarning = false;
    }

    this.model.mapTrip = !this.model.mapTrip;
  }
  /**
   * Switch the Kohlhammer toggle
   */
  switchKohlhammer(){
    if(!this.isEditingOfFieldsAllowed){
      return;
    }
    if (!this.kohlhammerLicenceStatus.available && !this.model.kohlhammer) {
      // Not available and therefore switch to true not allowed
      return;
    }

    if (this.model.kohlhammer) {
      // User wants to remove the licence > show warning
      this.showRemoveKohlhammerLicenceWarning = true;
    } else {
      this.showRemoveKohlhammerLicenceWarning = false;
    }

    this.model.kohlhammer = !this.model.kohlhammer;
  }

  switchVehicleCards(){
    if (!this.isEditingOfFieldsAllowed){
      return;
    }
    if (!this.vehicleCardsLicenceStatus.available && !this.model.vehicleCards) {
      // Not available and therefore switch to true not allowed
      return;
    }

    if (this.model.vehicleCards) {
      // User wants to remove the licence > show warning
      this.showRemoveVehicleCardsLicenceWarning = true;
    } else {
      this.showRemoveVehicleCardsLicenceWarning = false;
    }

    this.model.vehicleCards = !this.model.vehicleCards;

  }


  /**
   * Switch the dangerous goods toggle
   */
  switchDangerousGoods() {
    if (!this.isEditingOfFieldsAllowed){
      return;
    }
    if (!this.dangerousGoodsLicenceStatus.available && !this.model.dangerousGoods) {
      // Not available and therefore switch to true not allowed
      return;
    }

    if (this.model.dangerousGoods) {
      // User wants to remove the licence > show warning
      this.showRemoveDangerousGoodsLicenceWarning = true;
    } else {
      this.showRemoveDangerousGoodsLicenceWarning = false;
    }

    this.model.dangerousGoods = !this.model.dangerousGoods;
  }
  selectDiaryPermission(ePermission: ELocationMapDiaryPermission){
    if (ePermission === this.model.locationMaps.locationMapDiaryPermission){
      return;
    }
    this.model.locationMaps.locationMapDiaryPermission= ePermission;
  }

  selectConfidentialDiaryPermission(ePermission: ELicencePermissions){
    if (ePermission === this.model.locationMaps.confidentialDiaryPermission){
      return;
    }
    this.model.locationMaps.confidentialDiaryPermission= ePermission;
  }
  /**
   * Switch the location maps toggle
   */
  selectPermission(ePermission: ELicencePermissions) {
    if (ePermission === this.model.locationMaps.permissions){
      // nothing changed
      return;
    }
    this.doShowRemoveLocationMapsLicenceWarning(ePermission);
    if (!this.locationMapsLicenceStatus.available){
      if (this.model.locationMaps.permissions === this.licencePermissions.NO_ACCESS && ePermission!== this.licencePermissions.NO_ACCESS){
        // let not set read/right permission other than none if there are no free ones
        // still allows switching between read and write
        return;
      }

    }
    switch (ePermission){
      case ELicencePermissions.NO_ACCESS:
        this.model.locationMaps.locationMapDiaryPermission =undefined;
        this.model.locationMaps.confidentialDiaryPermission =ELicencePermissions.NO_ACCESS;
        break;
      case ELicencePermissions.READ:
        this.model.locationMaps.locationMapDiaryPermission = ELocationMapDiaryPermission.READ;
        break;
      case ELicencePermissions.WRITE:
        this.model.locationMaps.locationMapDiaryPermission = ELocationMapDiaryPermission.WRITE;
        break;
    }
    this.model.locationMaps.permissions = ePermission;
  }

  doShowRemoveLocationMapsLicenceWarning(ePermission:ELicencePermissions){
    if (ePermission === this.licencePermissions.NO_ACCESS){
      switch (this.model.locationMaps.permissions){
        case ELicencePermissions.READ:
        case ELicencePermissions.WRITE:
          this.showRemoveLocationMapsLicenceWarning = true;
          // reset to default
          this.model.locationMaps.locationMapDiaryPermission = ELocationMapDiaryPermission.READ;
          return;
        case ELicencePermissions.NO_ACCESS:
          this.showRemoveLocationMapsLicenceWarning = false;
      }
    }else {
      this.showRemoveLocationMapsLicenceWarning= false;
    }
  }
  isChangingPermissionAllowed():boolean{
    if (!this.isEditingOfFieldsAllowed){
      return false;
    }
    // always allow edit from read or write permission
    if (this.model.locationMaps.permissions !== this.licencePermissions.NO_ACCESS){
      return true;
    }
    return this.locationMapsLicenceStatus.available;
  }

  isLocationMapsLicenceSelected(){
    return this.model.locationMaps.permissions == ELicencePermissions.WRITE || this.model.locationMaps.permissions == ELicencePermissions.READ;
  }

  switchLocationMapDiaryPermission(){
    if (this.model.locationMaps.locationMapDiaryPermission === ELocationMapDiaryPermission.WRITE) {
      this.model.locationMaps.locationMapDiaryPermission = ELocationMapDiaryPermission.READ;
    }else {
      this.model.locationMaps.locationMapDiaryPermission = ELocationMapDiaryPermission.WRITE;
    }
  }



  /**
   * Switch the receive status toggle
   */
  switchReceiveStatus() {
    this.model.receiveStatus = !this.model.receiveStatus;
    if (!this.model.receiveStatus) {
      this.model.displayPositions = false;
    }
  }

  switchDisplayPositions() {
    this.model.displayPositions = !this.model.displayPositions;
  }

  switchAsTrackingSource() {
    this.model.asTrackingSource = !this.model.asTrackingSource;
  }

  switchSubmitStatus(value: ESubmitStatusValues) {
    this.model.submitStatus = value;
  }

  switchNavigationStatus3() {
    if (!this.isEditingOfFieldsAllowed){
      return;
    }
    this.model.navigationStatus3 = !this.model.navigationStatus3;
  }

  switchGpsStatus() {
    if (!this.isEditingOfFieldsAllowed){
      return;
    }
    this.model.gpsStatus = !this.model.gpsStatus;
  }

  switchReportsEditAll() {
    this.model.canEditAllMissionReports = !this.model.canEditAllMissionReports;
  }
  showReportPopup() {
    this.model.showMissionReportPopup = !this.model.showMissionReportPopup;
  }

  checkIfVehicleCardsLicenceIsAvailable() {
    this.isLoading = true;
    this.restService.checkIfLicenceAreAvailable(EAdditionalLicence.VEHICLE_CARDS, (response: LicenceAvailabilityResult) => {
      this.vehicleCardsLicenceStatus = response;
      this.$log.info('Vehicle Cards licences available: ' + this.vehicleCardsLicenceStatus);
      this.isLoading = false;
    }, (err) => {
      //Error occured
      this.isLoading = false;
    });
  }

  /**
   * Check if the Kohlhammer licence is available
   */
  checkIfKohlhammerLicenceIsAvailable(){
    this.isLoading = true;
    this.restService.checkIfLicenceAreAvailable(EAdditionalLicence.KOHLHAMMER, (response: LicenceAvailabilityResult) => {
      this.kohlhammerLicenceStatus = response;
      this.$log.info('Kohlhammer licences available: ' + this.kohlhammerLicenceStatus);
      this.isLoading = false;
    }, (err) => {
      //Error occured
      this.isLoading = false;
    });

  }
  /**
   * Check if the dangerous goods licence is available
   */
  checkIfDangerousGoodsLicenceIsAvailable() {
    this.isLoading = true;
    this.restService.checkIfLicenceAreAvailable(EAdditionalLicence.DANGEROUS_GOODS, (response: LicenceAvailabilityResult) => {
      this.dangerousGoodsLicenceStatus = response;
      this.$log.info('Dangerous Goods licences available: ' + this.dangerousGoodsLicenceStatus);
      this.isLoading = false;
    }, (err) => {
      //Error occured
      this.isLoading = false;
    });
  }

  /**
   * Check if the HERE maps licence is available
   */
  checkIfHereMapsLicenceIsAvailable() {
    this.isLoading = true;
    this.restService.checkIfLicenceAreAvailable(EAdditionalLicence.HERE_MAPS, (response: LicenceAvailabilityResult) => {
      this.hereMapsLicenceStatus = response;
      this.$log.info('Here Maps licences available: ' + this.hereMapsLicenceStatus);
      this.isLoading = false;
    }, (err) => {
      //Error occured
      this.isLoading = false;
    });
  }

  /**
   * Check if the MapTrip licence is available
   */
  checkIfMapTripLicenceIsAvailable() {
    this.isLoading = true;
    this.restService.checkIfLicenceAreAvailable(EAdditionalLicence.MAP_TRIP, (response: LicenceAvailabilityResult) => {
      this.mapTripLicenceStatus = response;
      this.$log.info('MapTrip licences available: ' + this.mapTripLicenceStatus);
      this.$log.info(this.mapTripLicenceStatus);
      this.isLoading = false;
    }, (err) => {
      //Error occured
      this.isLoading = false;
    });
  }
  /**
   * Delete all vehicle cards revoked tokens
   */
  deleteRevokedTokensVehicleCards() {
    this.isLoading = true;
    this.restService.deleteRevokedTokens(EAdditionalLicence.VEHICLE_CARDS, () => {
      this.checkIfVehicleCardsLicenceIsAvailable();
    }, () => {
      //Error occured
      this.isLoading = false;
    });
  }
  /**
   * Delete all Kohlhammer revoked tokens
   */
  deleteRevokedTokensKohlhammer(){
    this.isLoading = true;
    this.restService.deleteRevokedTokens(EAdditionalLicence.KOHLHAMMER, () => {
      this.checkIfKohlhammerLicenceIsAvailable();
    }, () => {
      //Error occured
      this.isLoading = false;
    });
  }
  /**
   * Delete all dangerous goods revoked tokens
   */
  deleteRevokedTokensDangerousGoods() {
    this.isLoading = true;
    this.restService.deleteRevokedTokens(EAdditionalLicence.DANGEROUS_GOODS, () => {
      this.checkIfDangerousGoodsLicenceIsAvailable();
    }, () => {
      //Error occured
      this.isLoading = false;
    });
  }

  /**
   * Delete all HERE maps revoked tokens
   */
  deleteRevokedTokensHereMaps() {
    this.isLoading = true;
    this.restService.deleteRevokedTokens(EAdditionalLicence.HERE_MAPS, () => {
      this.checkIfHereMapsLicenceIsAvailable();
    }, () => {
      //Error occured
      this.isLoading = false;
    });
  }

  /**
   * Delete all MapTrip revoked tokens
   */
  deleteRevokedTokensMapTrip() {
    this.isLoading = true;
    this.restService.deleteRevokedTokens(EAdditionalLicence.MAP_TRIP, () => {
      this.checkIfMapTripLicenceIsAvailable();
    }, () => {
      //Error occured
      this.isLoading = false;
    });
  }
  /**
   * Delete all location maps revoked tokens
   */
  deleteRevokedTokensLocationMaps() {
    this.isLoading = true;
    this.restService.deleteRevokedTokens(EAdditionalLicence.LOCATION_MAPS, () => {
      this.checkIfLocationMapsLicenceIsAvailable();
    }, () => {
      //Error occured
      this.isLoading = false;
    });
  }

  openDeleteTokensModal(licence: EAdditionalLicence) {
    if (!this.isEditingOfFieldsAllowed){
      return;
    }
    this.$uibModal.open({
      template: require('./../delete.revoked.tokens.modal/delete.revoked.tokens.modal.html'),
      controller: 'DeleteRevokedTokensModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return () => {
            if (licence === EAdditionalLicence.DANGEROUS_GOODS) {
              this.deleteRevokedTokensDangerousGoods();
            } else if (licence === EAdditionalLicence.HERE_MAPS) {
              this.deleteRevokedTokensHereMaps();
            } else if (licence == EAdditionalLicence.KOHLHAMMER){
              this.deleteRevokedTokensKohlhammer();
            } else if (licence == EAdditionalLicence.VEHICLE_CARDS){
              this.deleteRevokedTokensVehicleCards();
            }else if (licence == EAdditionalLicence.LOCATION_MAPS){
              this.deleteRevokedTokensLocationMaps()
            } else if (licence == EAdditionalLicence.MAP_TRIP) {
              this.deleteRevokedTokensMapTrip()
            }
          }
        }
      }
    });
  }

  /**
   * Get the link to waldbrand login
   */
  getUserLink(): string {
    return this.model.link;
  }

  selectOnMap() {
    //Update
    this.$uibModal.open({
      template: require('../../../modals/alarms/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseAlarmOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return {
            lat: undefined,
            lng: undefined
          }
        },
        position: () => {
          return undefined;
        },
        okFunction: () => {
          return (coords) => {
            this.model.centerLat = coords.lat;
            this.model.centerLng = coords.lng;
          }
        }
      }
    });
  }

  private checkIfLocationMapsLicenceIsAvailable() {
    this.isLoading = true;
    this.restService.checkIfLicenceAreAvailable(EAdditionalLicence.LOCATION_MAPS, (response: LicenceAvailabilityResult) => {
      this.locationMapsLicenceStatus = response;
      this.$log.info('Vehicle Cards licences available: ' + this.locationMapsLicenceStatus);
      this.isLoading = false;
    }, (err) => {
      //Error occured
      this.isLoading = false;
    });
  }
}

export enum AmobileModes {
  GENERAL = 'GENERAL',
  SETTINGS = 'SETTINGS',
  NAVIGATION = 'NAVIGATION',
  LOCATION_MAPS = 'LOCATION_MAPS',
  MISSION_REPORTS = 'MISSION_REPORTS',
  MISC = 'MISC'
}
