'use strict';

import angular = require("angular");
import { UserAccount } from "../../../../data/account.data";
import { User } from "../../../../data/admin.data";
import { CustomFeedbackResponseResponse } from "../../../../data/customFeedback.data";
import { RolePrivilege } from "../../../../data/privileges.enum";
import { Unit, Splitter, UnitSettings, EAddressCalculationMode, UnitFilterEntry, EUnitType } from "../../../../data/unit.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./unit.settings.modal.css')

/* @ngInject */
export default class UnitSettingsModalController {

  public isLoading: boolean;
  public hasOnlineService: boolean = false;
  public mode: string = 'GENERAL';
  public availableSplitters: Splitter[] = [];

  public addressMode_COORDINATES_DEZ: boolean;
  public addressMode_COORDINATES_HEX: boolean;
  public addressMode_COORDINATES_GK: boolean;
  public addressMode_STREET_DICTIONARY: boolean;
  public addressMode_CODE: boolean;
  public addressMode_BUILDING: boolean;
  public modesEnum: typeof EAddressCalculationMode = EAddressCalculationMode;

  public users: UnitFilterEntry[];

  public unitFilter = (u: UnitFilterEntry) => u.units.length !== 0;

  public userHasLeaderboardActive: boolean;

  constructor(private $uibModalInstance: any,
    dataService,
    private $log: angular.ILogService,
    private $scope: angular.IScope,
    private unit: Unit,
    public user: User,
    public restService: RestService,
    public privilegeService: PrivilegeService,
    private customFeedbackList: CustomFeedbackResponseResponse[]
  ) {
    restService.loadAvailableSplitters().then(val => {
      this.availableSplitters = val;
    });
    for (let mode of unit.settings.addressCalculationModes) {
      this['addressMode_' + mode] = true;
    }


    if (user) {
      this.hasOnlineService = user.hasOnlineService;
    } else {
      const account = (dataService.getAccount() as UserAccount);
      this.hasOnlineService = account.hasOnlineService;
      this.userHasLeaderboardActive = account.settings.useLeaderboard;
    }

    if (this.privilegeService.has(RolePrivilege.Admin_Users) && [EUnitType.SAMMEL, EUnitType.EXPRESS, EUnitType.WACHALARM_PRE].includes(this.unit.type)) {
      this.loadUsersWithDefaultUnits();
    }
  }

  close() {
    this.$uibModalInstance.close();
  }

  save() {
    this.isLoading = true;
    this.unit.settings.addressCalculationModes = this.getAddressCalculationModes();

    if (!this.unit.settings.hasCustomFeedback) {
      this.unit.settings.customFeedbackId = undefined;
    }

    if (this.unit.settings.doubleAlarmTime < 0) {
      this.unit.settings.doubleAlarmTime = 0
    }

    if (!this.unit.settings.useFeedback) {
      this.unit.settings.useForLeaderboard = false;
    }

    this.restService.saveUnitSettings(this.unit.id, this.unit.settings, (data: UnitSettings) => {
      this.$log.debug(data);
      this.unit.settings = data;
      this.isLoading = false;
    },
      (response) => {
        //Error occured
        this.$log.error(response);
        this.isLoading = false;
      });
  }


  getSelectedCustomFeedback() {
    if (this.unit.settings.customFeedbackId === null) return "---";
    for (let feedback of this.customFeedbackList) {
      if (feedback.id === this.unit.settings.customFeedbackId) {
        return feedback.name;
      }
    }
    this.unit.settings.customFeedbackId = null
    return "---";
  }

  loadUsersWithDefaultUnits() {
    this.restService.getAllUsersWithDefaultUnits()
      .then(users => this.users = users)
      .catch(err => this.$log.error(err))
      .finally(() => this.$scope.$applyAsync());
  }


  getAddressCalculationModes(): EAddressCalculationMode[] {
    let modes: EAddressCalculationMode[] = [];
    for (let mode in this.modesEnum) {
      if (this['addressMode_' + mode]) {
        modes.push(EAddressCalculationMode[mode])
      }
    }
    return modes;
  }

  toggleCodeToSammel(unitCode: string) {
    let index = this.unit.settings.sammelUnitSettings.codeToSammel.indexOf(unitCode);
    if (index < 0) {
      this.unit.settings.sammelUnitSettings.codeToSammel.push(unitCode);
      return;
    }
    this.unit.settings.sammelUnitSettings.codeToSammel.splice(index, 1);
  }

  toggleTriggerCodes(unitCode: string) {
    let index = this.unit.settings.sammelUnitSettings.triggerCodes.indexOf(unitCode);
    if (index < 0) {
      this.unit.settings.sammelUnitSettings.triggerCodes.push(unitCode);
      return;
    }
    this.unit.settings.sammelUnitSettings.triggerCodes.splice(index, 1);
  }

  toggleExpress(unitCode: string) {
    let index = this.unit.settings.expressCodes.indexOf(unitCode);
    if (index < 0) {
      this.unit.settings.expressCodes.push(unitCode);
      return;
    }
    this.unit.settings.expressCodes.splice(index, 1);
  }

  toggleWachalarmTriggerCode(unitCode: string) {
    let index = this.unit.settings.wachalarmSettings.triggerCodes.indexOf(unitCode);
    if (index < 0) {
      this.unit.settings.wachalarmSettings.triggerCodes.push(unitCode);
      return;
    }
    this.unit.settings.wachalarmSettings.triggerCodes.splice(index, 1);
  }

  toggleWachalarmRelated(unitCode: string) {
    let index = this.unit.settings.wachalarmSettings.relatedUnitCodes.indexOf(unitCode);
    if (index < 0) {
      this.unit.settings.wachalarmSettings.relatedUnitCodes.push(unitCode);
      return;
    }
    this.unit.settings.wachalarmSettings.relatedUnitCodes.splice(index, 1);
  }


}